import { ShippingMethod } from '@rediredi/types'
import { SelectOption } from '~/components/base/RSelectField.vue'

export const useDeliveryOptions = () => {
  const tPrefix = 'components.checkoutDeliveryAddressForm'

  const deliveryOptions = computed(() => {
    const { t } = useI18n()
    const globalStore = useGlobalStore()

    const { delivery, pickup, room, digital } = globalStore.store.releaseMethod ?? {
      delivery: false,
      pickup: false,
      room: false,
      digital: false,
    }
    const options: SelectOption[] = []

    if (delivery) {
      options.push({
        value: ShippingMethod.Delivery,
        label: t(`${tPrefix}.deliveryOptions.delivery`),
        isDisabled: false,
        icon: 'material-symbols:local-shipping-outline-rounded',
      })
    }

    if (pickup) {
      options.push({
        value: ShippingMethod.Pickup,
        label: t(`${tPrefix}.deliveryOptions.pickup`),
        isDisabled: false,
        icon: 'material-symbols:storefront-outline-rounded',
      })
    }

    if (digital) {
      options.push({
        value: ShippingMethod.Digital,
        label: t(`${tPrefix}.deliveryOptions.digital`),
        isDisabled: false,
        icon: 'ic:round-cloud-download',
      })
    }

    if (room) {
      options.push({
        value: ShippingMethod.Room,
        label: t(`${tPrefix}.deliveryOptions.room`),
        isDisabled: false,
        icon: 'mdi:room-service',
      })
    }

    if (options.length === 0) {
      options.push({
        value: ShippingMethod.ToBeArranged,
        label: t(`${tPrefix}.deliveryOptions.toBeArranged`),
        isDisabled: false,
        icon: 'ic:outline-handshake',
      })
    }

    return options
  })

  return {
    options: deliveryOptions,
  }
}
